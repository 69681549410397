

<template>
    <v-card>
        <v-card-title>
            <div class="card-header text-unset">{{ tableTitle }}</div>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-data-table dense :headers="header" :items="programs" item-key="id">
            <template v-slot:items="props">
                <td :style="getBGColor(props.item.nextPaymentStart, props.item.nextPaymentEnd)">
                    {{ props.item.user.username }}
                    <span class="font-weight-bold float-right" v-if="props.item.user.viewerLeg === 1">L</span>
                    <span class="font-weight-bold float-right" v-else-if="props.item.user.viewerLeg === 2">R</span>
                </td>
                <td :style="getBGColor(props.item.nextPaymentStart, props.item.nextPaymentEnd)">
                    {{ props.item.deposits[0].sequenceNumber }}
                </td>

                <td class="date-time-width" :style="getBGColor(props.item.nextPaymentStart, props.item.nextPaymentEnd)">
                    <div>{{ props.item.id | formatDate }}</div>
                </td>

                <td :style="getBGColor(props.item.nextPaymentStart, props.item.nextPaymentEnd)">
                    {{ props.item.deposits[0].id | formatDate }}
                </td>


                <td class="date-time-width" :style="getBGColor(props.item.nextPaymentStart, props.item.nextPaymentEnd)">
                    <div>{{ props.item.nextPaymentStart | formatDate }}</div>
                </td>


                <td class="" :style="getBGColor(props.item.nextPaymentStart, props.item.nextPaymentEnd)">
                    <div class="widget-content p-0" v-if="new Date(props.item.nextPaymentEnd) >= +new Date()">
                        <div class="widget-content-outer">
                            <div class="widget-content-wrapper">
                                <div class="widget-content-left pr-2">
                                    <div
                                        :class="'widget-numbers fsize-1 text-' + getTextColorClass(props.item.nextPaymentStart, props.item.nextPaymentEnd)">

                                        {{ (getPercentage(props.item.nextPaymentStart) > 100) ? 100 :
                                            getPercentage(props.item.nextPaymentStart) }}%
                                    </div>
                                </div>
                                <div class="widget-content-right w-100">
                                    <div class="progress-bar-xs progress">
                                        <div class="progress-bar" :class="'bg-' +

                                            getTextColorClass(props.item.nextPaymentStart, props.item.nextPaymentEnd)"
                                            role="progressbar" :aria-valuenow="getPercentage(props.item.nextPaymentStart)"
                                            aria-valuemin="0" aria-valuemax="100"
                                            :style="'width: ' + getPercentage(props.item.nextPaymentStart) + '%;'">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
                <td class="" :style="getBGColor(props.item.nextPaymentStart, props.item.nextPaymentEnd)">
                    <button class="mb-2 mr-2 opacity-10 btn badge-btn" :class="'btn-primary'" disabled>
                        {{ getFreedomProgramNames(props.item.programName) }}
                    </button>
                </td>
                <td class="value-min-width" :style="getBGColor(props.item.nextPaymentStart, props.item.nextPaymentEnd)">
                    <span>{{ props.item.deposits[0].valueInTokens | formatHtl }}</span> HTL / <span>{{
                        props.item.deposits[0].valueInFiat |
                        formatEur
                    }}</span> EUR
                </td>
                <td class="value-min-width" :style="getBGColor(props.item.nextPaymentStart, props.item.nextPaymentEnd)">
                    <span>{{ props.item.totalValueInTokens | formatHtl }}</span> HTL / <span>{{
                        props.item.totalValueInFiat | formatEur }}</span> EUR
                </td>
            </template>
        </v-data-table>

    </v-card>
</template>
<script>
//import { library } from '@fortawesome/fontawesome-svg-core'
//import { faArrowUp, faArrowLeft, faArrowRight, faMale, faAddressBook } from '@fortawesome/free-solid-svg-icons'
//import UpgradePackageModal from "@/components/modals/UpgradePackageModal";
//import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
//library.add(faArrowUp, faArrowLeft, faArrowRight, faMale, faAddressBook);
export default {
    name: "FreedomProgramTable",
    components: {

    },
    data() {
        return {
            search: "",
            headers: [
                {
                    value: "username",
                    text: this.$i18n.t("username"),
                    sortable: false,
                },
                {
                    value: "leg",
                    text: this.$i18n.t("leg"),
                    sortable: false,
                },
                { value: "packageId", text: this.$i18n.t("date"), sortable: false, class: "text-center" },
                { value: "expires", text: this.$i18n.t("expires"), sortable: true, sort: (a, b) => +new Date(a) < +new Date(b) ? -1 : 1 },
                { value: "type", text: this.$i18n.t("type"), sortable: false },
                { value: "fresh", text: this.$i18n.t("fresh"), sortable: false },
                { value: "value", text: this.$i18n.t("value"), sortable: false },
            ],
            vouhers: null
        };
    },
    props: [
        "programs",
        "tableTitle",
        "header",
        //     "lockedRate"
    ],
    beforeMount() {
    },
    methods: {

        getBGColor(nextPaymentStart, nextPaymentEnd) {
            if (new Date(nextPaymentEnd) <= +new Date()) {
                return 'background-color:#facdc8;';
            }
            if (new Date(nextPaymentStart) <= +new Date()) {
                return 'background-color:#f7edc3;';
            }
            return '';

        },
        getTextColorClass(nextPaymentStart, nextPaymentEnd) {
            if (new Date(nextPaymentEnd) <= +new Date()) {
                return 'critical';
            }
            if (new Date(nextPaymentStart) <= +new Date()) {
                return 'warning';
            }
            return 'primary';

        },
        getPercentage(nextPaymentDate) {
            return Math.round(

                (30 //št. dni kakor je dolgo obdobje
                    - ((new Date(nextPaymentDate) - new Date())
                        /
                        (1000 * 60 * 60 * 24))) * 3.333 //3.333 je en dan v procentih ali izracunano 1/30
            );
        },
        getFreedomProgramNames(type) {
            switch (type) {
                case '50-fp2':
                case '50-fp1':
                    return 'BASIC';
                case '100-fp1':
                case '100-fp2':
                    return 'ADVANCED';
                case '200-fp1':
                case '200-fp2':
                    return 'PREMIUM';
            }
        },
    }
}
</script>
<style scoped>
.badge-dot-text {
    text-indent: 0em !important;
    width: 16px !important;
    height: 16px !important;
    color: white !important;
    border-radius: 50px !important;
    font-size: 0.5rem;
    padding: 2px 0 2px 0;
}

.badge-btn {
    min-width: 110px;
    border-radius: 50px !important;
}

.date-time-width {
    min-width: 150px;
}

.value-min-width {
    min-width: 150px;
}

.bg-critical {
    background-color: white;
}

.text-critical {
    color: white;
}
</style>