<template>
    <div v-if="page">
        <h2>All Packages & Freedom Program</h2>
        <packages-table :header="activeHeaders"
            :packages="page.viewer.downlinePackages.filter(p => p.active).map(p => this.moreInfo(p))" class="mb-3"
            table-title="ACTIVE LOYALTY PROGRAM" />
        <freedom-program-table table-title="ACTIVE FREEDOM PROGRAM" :header="freedomActiveHeaders"
            :programs="downlineFreedomProgramSorted" class="mb-3" />
        <!-- :header="activeHeaders" :packages="page.viewer.downlinePackages.filter(p => p.active).map(p => this.moreInfo(p))" :table-title="$t('Active packages')" -->
        <packages-table :header="expiredHeaders"
            :packages="page.viewer.downlinePackages.filter((p) => !p.active && (p.sellAt) && p.sellBackStatus < 2).map((p) => this.transformDataExpired(p))"
            table-title="ACTIVE DIVIDEND PROGRAM" class="mb-3" />
        <exclusives-table :allUsers="true" :packages="page.downlineEra" tableTitle="ACTIVE EXCLUSIVE RESORT ACCESSES"
            :header="exclusivesHeaders"></exclusives-table>
        <packages-history-table class="my-3" table-title="HISTORY"
            :packages="page.viewer.downlinePackages.filter((p) => !p.active && (!(p.sellAt) || p.sellBackStatus > 1)).map((p) => this.transformDataExpired(p))"
            :header="historyHeaders" />


    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PackagesTable from "@/components/Packages/PackagesTable.vue";
import ExclusivesTable from "@/components/Packages/ExclusivesTable";
import FreedomProgramTable from "@/components/Packages/FreedomProgramTable.vue";
import PackagesHistoryTable from "@/components/Packages/PackagesHistoryTable";
import gql from "graphql-tag";
export default {
    name: "AllPackages",
    components: { PackagesTable, FreedomProgramTable, PackagesHistoryTable, ExclusivesTable },
    data() {
        return {

            downlineFreedomProgramSorted: null,
            page: null,
            activeHeaders: [
                { value: "user", text: this.$i18n.t("username"), sortable: false, class: "text-center" },
                { value: "id", text: "ID", sortable: false, },
                { value: "id", text: this.$i18n.t("date"), sortable: false, class: "text-center" },
                { value: "expires", text: this.$i18n.t("loyalty-expires"), sortable: false },
                { value: "type", text: this.$i18n.t("type"), sortable: false },
                { value: "filled", text: this.$i18n.t("fresh"), sortable: false },
                { value: "value", text: this.$i18n.t("value"), sortable: false },
            ],
            expiredHeaders: [
                { value: "user", text: this.$i18n.t("username"), sortable: false, class: "text-center" },
                { value: "id", text: "ID", sortable: false, },
                { value: "id", text: this.$i18n.t("date"), sortable: false, class: "text-center" },
                { value: "expires", text: this.$i18n.t("loyalty-expired"), sortable: false },
                { value: "type", text: this.$i18n.t("type"), sortable: false },
                { value: "filled", text: this.$i18n.t("fresh"), sortable: false },
                { value: "value", text: this.$i18n.t("value"), sortable: false },
            ],
            historyHeaders: [
                { value: "user", text: this.$i18n.t("username"), sortable: false, class: "text-center" },
                { value: "ID", text: "ID", sortable: false },
                { value: "startDate", text: this.$i18n.t("start-date-end-date"), sortable: false, class: "text-center" },
                { value: "packageId", text: "Sold / Convert date", sortable: false, class: "text-center" },
                { value: "type", text: this.$i18n.t("type"), sortable: false },
                { value: "fresh", text: this.$i18n.t("value"), sortable: false },
                { value: "value", text: "Status", sortable: false },
            ],
            freedomActiveHeaders: [
                { value: "user", text: this.$i18n.t("username"), sortable: false, class: "text-center" },
                { value: "installment", text: 'No.', sortable: false, class: "text-center" },
                { value: "id", text: "FP Started", sortable: false, class: "text-center" },
                { value: "id", text: "Payment date", sortable: false, class: "text-center" },
                { value: "nextPay", text: "Next payment date", sortable: false },
                { value: "percentage", text: "Till next payment", sortable: false },
                { value: "program", text: "Freedom Program", sortable: false },
                { value: "value", text: "Value", sortable: false },
                { value: "total", text: "Total Value", sortable: false },
            ],
            exclusivesHeaders: [

                { value: "User", text: "User", sortable: false },
                { value: "ID", text: "ID", sortable: false },
                { value: "startDate", text: "Purchase date", sortable: false },

            ],
        }
    },
    computed: {
        ...mapGetters(
            {
                active: 'packages/activePackages',
                activeCount: 'packages/activePackagesCount',
                expired: 'packages/expiredPackages',
                expiredCount: 'packages/expiredPackagessCount',
            }
        )
    },
    apollo: {
        page: {
            query: gql`query{viewer{id,downlinePackages{id,active,expiresAt,upgradedAt,sellAt,sellBackStatus,sellBackDate,packageName,noCommission,totalValueInTokens,freshValueInTokens,valueInFiat,user{id,username,viewerLeg,viewerPath}},downlineFreedomProgram{id,active,nextPaymentStart,nextPaymentEnd,programEnd,programName,programFriendlyName,totalValueInFiat,totalValueInTokens,user{id,username,viewerLeg,viewerPath},deposits{id,sequenceNumber,valueInFiat,valueInTokens,paymentDelay, rewardInstallment}}},viewer{totals{tokens}},viewerIsAdmin, downlineEra{id,user{username,viewerLeg} }}`,
            update: data => data,
            result({ data }) {
                if (data) {
                    this.$store.commit('user/updateAvailable', {
                        tokens: data.viewer.totals.tokens
                    })
                    if (data.viewerIsAdmin) {
                        this.$store.commit('user/setAdmin')
                    }

                    this.downlineFreedomProgramSorted = data.viewer.downlineFreedomProgram.sort((a, b) => (a.nextPaymentStart > b.nextPaymentStart ? 1 : -1))
                }
            }
        }
    },
    methods: {
        moreInfo(p) {
            return {
                color: p.valueInFiat[0] < 9000000 ? 'primary' : p.valueInFiat[0] < 90000000 ? 'success' : 'dark',
                ...p
            }
        },
        transformDataExpired(p) {
            return {
                color:
                    p.valueInFiat[0] < 19000000
                        ? "primary"
                        : p.valueInFiat[0] < 900000000
                            ? "success"
                            : "dark",
                ...p,
            };
        }
    }
}
</script>

<style scoped></style>